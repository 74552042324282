/**
 *
 * @module dropdown
 * @extends module:_selectedWidget
 */
$.widget("tcg.dropdown", $.tcg._selectedWidget, {
    version: "2.0.7",
    options: {
        /**
         * @memberOf module:dropdown
         * @property {string} [emptyText='---请选择---'] - 未選取狀態下的文字
         * @instance
         */
        emptyText: "---请选择---",
        /**
         * @memberOf module:dropdown
         * @property {boolean} [corner=false] - 是否圓角
         * @instance
         */
        corner: false
    },
    _create: function()  {
        this._super();

        //  加載class
        this.widget().addClass("tcg-dropdown tcg-corner-all");
        var _this = this;
        $.each(this.options.classes, function(key, value){
            _this.widget().find("." + key).addClass(value);
        });

        //  事件監聽
        this._on(this.widget(), {
            "click .tcg-dropdown-text": function (e) {
                this.widget().toggleClass("tcg-open")
            },
            "click li": function (e) {
                var $li = $(e.currentTarget);
                var text = $li.text(), index = e.currentTarget.dataset.index,
                    isChange = this.options.index != index;

                //  set index
                this.options.index = index;
                //  set selected
                $li.attr("selected", true)
                    .siblings("li").attr("selected", false);
                //  set text
                this.widget().find(".tcg-dropdown-inner").text(text);
                //  close
                this.widget().removeClass("tcg-open");

                //  trigger
                /**
                 * 點擊下拉選單時，觸發的事件
                 *
                 * @event change
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 選取的資料。{index,data}
                 */
                this._trigger("click", e, {index: index, data: this.options.data[index]});
                if(isChange){
                    /**
                     * 選取的索引改變時，觸發的事件
                     *
                     * @event change
                     *
                     * @param {Event} event - 事件
                     * @param {object} data - 選取的資料。{index,data}
                     */
                    this._trigger("change", e, {index: index, data: this.options.data[index]});
                }
            }
        });
        this._on(document, {
            //  點擊任何不屬於該元件的部份，即關閉下拉選單
            "click": function(e){
                if(this.widget().find(e.target).length == 0){
                    this.widget().removeClass("tcg-open");
                }
            }
        });
    },
    _init: function() {
        this._super();

        this.options.emptyText = this._constrainEmptyText(this.options.emptyText);
        this.options.corner = this._constrainCorner(this.options.corner);

        this.refresh();
    },
    refresh: function () {

        //  重繪
        this._refresh();

        //  重繪文字
        this._refreshText();

        //  重繪按鈕
        this._refreshList();
    },
    _refresh: function () {
        var clazz = "tcg-widget tcg-dropdown ";

        //  add 原本的class
        clazz += this.originClass;
        //  設定圓角
        clazz += this.options.corner ? ' tcg-round-corner' : ' tcg-corner-all';
        //  設定大小
        clazz += ' tcg-s' + this.options.size;

        //  設定class
        this.widget().attr("class", clazz);
        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);
        //  設定style
        if(this.options.width){
            this.widget().css("width", this.options.width / 100 + "rem");
        }
        //  清除內容
        this.widget().html('');
    },
    _refreshText: function () {
        var html = '', data = this.getSelectedData(),
            text = data != null ? data.text : this.options.emptyText;

        html += '<div class="tcg-dropdown-text">';
        html += '   <div class="tcg-dropdown-inner">' + text + '</div>';
        html += '   <div class="tcg-suffix-icon"><div class="tcg-icon tcg-icon-solid-arrow-bottom"></div></div>';
        html += '</div>';

        this.widget().append(html);
    },
    _refreshList: function () {
        var html = '', style = '';

        html += '<div class="tcg-dropdown-arrow"></div>';
        html += '<div class="tcg-dropdown-list" style="' + style + '"><ul>';

        if(this.options.allowEmpty){
            html += '<li data-index="-1" ' + (this.options.index == -1 ? 'selected' : '') + '>' + this.options.emptyText + '</li>';
        }

        for(var i = 0; i < this.options.data.length; i++){
            html += '<li data-index="' + i + '" ' + (this.options.index == i ? 'selected' : '') + '>' + this.options.data[i].text + '</li>';
        }
        html += '</ul></div>';

        this.widget().append(html);

        if(this.options.width){
            this.widget().find(".tcg-dropdown-list").css("width", this.options.width / 100 + "rem");
        }
    },
    _destroy: function() {
        this._removeClass("tcg-dropdown tcg-corner-all");
        this.widget().children().remove();
    },
    /**
     * <h4>取得未選取狀態下的文字</h4>
     *
     * @instance
     *
     * @return {string} 未選取狀態下的文字
     */
    getEmptyText: function () {
        return this.options.emptyText;
    },
    _constrainIndex: function (index) {
        return Number.isInteger(index) && Number(index) >= 0 ? Number(index) : 0;
    },
    _constrainEmptyText: function (emptyText) {
        return typeof emptyText == 'string' ? emptyText : '---请选择---';
    },
    _constrainCorner: function (corner) {
        return typeof corner == 'boolean' ? corner : false;
    }
});
