/**
 * @module tabs
 * @extends module:_widget
 */
$.widget("tcg.tabs", $.tcg._singleSelectedWidget, {
    version: "2.0.7",
    options: {
        /**
         * @memberOf module:tabs
         * @property {string} [side="top"] - 頁籤位置：top, left, right
         * @instance
         */
        side: "top",
        /**
         * @memberOf module:tabs
         * @property {boolean} [corner=false] - 頁籤是否圓角
         * @instance
         */
        corner: false,
        /**
         * @memberOf module:tabs
         * @property {number} [index=0] - 預設顯示第幾個頁籤
         * @instance
         */
        index: 0
    },
    _create: function()  {
        this._super();

        //  加載class
        this.widget().addClass("tcg-tabs");

        var _this = this;

        $.each(this.options.classes, function (key, value) {
            _this.widget().find("." + key).addClass(value);
        });

        var tabsWrap    = this.widget();
        var tabSelector = tabsWrap.find(".tcg-tab-list>li");
        var tabContent  = tabsWrap.find(".tcg-tab-content");

        //  事件監聽
        this._on(this.widget(), {
            "click .tcg-tab-list>li": function(e) {
                var index = Number(e.currentTarget.dataset.index),
                    isChange = this.options.index != index;
                var clickedTab  = $(e.currentTarget).attr("data-order");
                var tabsParent  = $(e.currentTarget).closest(".tcg-tabs");

                //  change index
                this.options.index = index;

                tabContent.removeClass("active")
                tabSelector.removeClass("active");
                $(e.currentTarget).addClass("active");
                tabsParent.find(".tcg-tab-content." + clickedTab).addClass("active");

                /**
                 * 點擊下拉選單時，觸發的事件
                 *
                 * @event change
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 選取的資料。{index,data}
                 */
                this._trigger("click", e, {index: index, data: this.options.data[index]});
                /**
                 * 選取的索引改變時，觸發的事件
                 *
                 * @event change
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 選取的資料。{index,data}
                 */
                if(isChange){
                    this._trigger("change", e, {index: index, data: this.options.data[index]});
                }
            }
        });
    },
    _init: function() {
        this._super();

        this.options.index = this.options.index == null || this.options.index == undefined ? 0 : this.options.index;
        this.options.side = this.options.side ? this.options.side : "top";

        this.refresh();
    },
    refresh: function () {
        var _this       = this;
        var side        = this.options.side;
        var index       = this.options.index;
        var corner      = this.options.corner;
        var tabsWrap    = this.widget();
        var tabSelector = tabsWrap.find(".tcg-tab-list>li");
        var tabContent  = tabsWrap.find(".tcg-tab-content");

        tabsWrap.addClass(side + "-side");

        if (corner) {
            tabsWrap.find(".tcg-tab-list>li").addClass("tcg-corner-top");
        }

        tabSelector.removeClass("active");
        tabContent.removeClass("active");

        tabsWrap.find(".tcg-tab-list>li:eq(" + index + ")").addClass("active");
        tabsWrap.find(".tcg-tab-content:eq(" + index + ")").addClass("active");

        tabSelector.each(function(index) {
            var tabsWrapOrder = $(this).parents(".tcg-tabs").index(".tcg-tabs") + 1;
            var addRelation   = "tab-" + tabsWrapOrder + "-" + (index + 1);
            var text = !_this.options.data[index] || !_this.options.data[index].text ? $(this).text() : _this.options.data[index].text;

            $(this).attr("data-order", addRelation);
            $(this).attr("data-index", index);

            _this.options.data[index] = $.extend(_this.options.data[index], {
                text: text
            });
            $(this).text(text);
        });

        tabContent.each(function(index) {
            var tabsWrapOrder = $(this).parents(".tcg-tabs").index(".tcg-tabs") + 1;
            var addRelation   = "tab-" + tabsWrapOrder + "-" + (index + 1);

            $(this).addClass(addRelation);
            $(this).attr("data-order", addRelation);
            $(this).attr("data-index", index);
        });
    },
    _destroy: function() {
        this._removeClass("tcg-tabs").empty();
    }
});
