/**
 *
 * @module slider
 * @extends module:_widget
 */
$.widget("tcg.slider", $.tcg._widget, {
    version: "2.0.7",
    options: {
        /**
         * @memberOf module:slider
         * @property {number} [range=false] - 是否範圍值
         * @instance
         */
        range: false,
        /**
         * @memberOf module:slider
         * @property {number} [min=0] - 最小值
         * @instance
         */
        min: 0,
        /**
         * @memberOf module:slider
         * @property {number} [min=null] - 最大值，null的時候表示不限
         * @instance
         */
        max: 100,
        /**
         * @memberOf module:slider
         * @property {number} [scale=1] - 尺度，每次點擊按鈕數值變化的程度
         * @instance
         */
        scale: 1,
        /**
         * @memberOf module:slider
         * @property {number} [width=200] - 元件寬度
         * @instance
         */
        width: 120,
        /**
         * @memberOf module:slider
         * @property {integer} [precision=0] - 精確度 (0:整數，1:小數點後一位，以此類推)
         * @instance
         */
        precision: 0,
        /**
         * @memberOf module:slider
         * @property {number | Array} [value=1] - 數值
         * @instance
         */
        value: 1,
        /**
         * @memberOf module:slider
         * @property {number} [maxColor=null] - 最大值時，背景切換的顏色
         * @instance
         */
        maxColor: null,
        /**
         * @memberOf module:slider
         * @property {boolean} [toolTip=true] - 是否顯示 tooltip
         * @instance
         */
        toolTip: true

    },
    //  左邊值
    leftValue: null,
    //  右邊值
    rightValue: null,
    //  左邊游標jQuery物件
    leftHandler: null,
    //  右邊游標jQuery物件
    rightHandler: null,
    //  最大寬度 width - size
    maxWidth: null,
    //  進度條jQuery物件
    progressBar: null,
    //  實際最大值：max-min
    max: null,
    _create: function () {
        this._super();

        this._on(this.widget(), {
            "drag .tcg-handler": function (e, ui) {

                this._setProgressBar(0, (ui.position.left / this.maxWidth * 100));
                this._setValue(ui.position.left / this.maxWidth * (this.options.max - this.options.min) + this.options.min);


                /**
                 * slider滾動時，觸發的事件
                 *
                 * @event change
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料
                 */
                this._trigger("change", e, {
                    value: this.getValue()
                });
            }
        })
    },
    _init: function () {
        this._super();

        this.options.range = this._constrainRange(this.options.range);
        this.options.min = this._constrainMin(this.options.min);
        this.options.max = this._constrainMax(this.options.max);
        this.options.scale = this._constrainScale(this.options.scale);
        this.options.width = this._constrainWidth(this.options.width);
        this.options.precision = this._constrainPrecision(this.options.precision);
        this.options.value = this._constrainValue(this.options.value);
        this.options.maxColor = this._constrainMaxColor(this.options.maxColor);

        //  最大寬度 = 寬度 - size
        this.maxWidth = (this.options.width - this.options.size);
        this.max = this.options.max - this.options.min;

        this.refresh();
    },
    refresh: function () {
        var clazz = 'tcg-widget tcg-slider ';

        //  origin
        clazz += this.originClass + ' ';
        //  size
        clazz += 'tcg-s' + this.options.size;

        this.widget().attr("class", clazz);
        this.widget().attr("style", this.originStyle);
        this.widget().css({
            width: this.options.width / 100 + "rem"
        });

        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);
        //  重繪UI
        this._refreshUI();
    },
    _refreshUI: function () {
        var html = '',
            rnd = this._rnd();

        html += '<div class="tcg-slider-progress">';
        html += '   <div class="tcg-slider-progress-bar"></div>';
        html += '</div>';
        html += '<div class="tcg-slider-range parent-range-' + rnd + '">';
        html += '   <div class="tcg-handler left-handler" style="display: none;"></div>';
        html += '   <div class="tcg-handler right-handler"><span class="tcg-handler-nuv"><span>'+this.options.value+'</span></span></div>';
        html += '</div>';

        //  重置上次refresh的draggable
        this.widget().find(".tcg-handler").draggable("destroy");
        //  串接html生成UI
        this.widget().html(html);
        //  設定進度條的寬度
        this.widget().find(".tcg-slider-progress").css({
            width: this.options.width / 100 + "rem"
        });
        //  將handler設為draggable
        this.widget().find(".tcg-handler").draggable({
            containment: '.parent-range-' + rnd
        });
        //  儲存常用的jquery物件
        this.progressBar  = this.widget().find(".tcg-slider-progress-bar");
        this.rightHandler = this.widget().find(".right-handler");
        this.toolTip      = this.widget().find(".tcg-handler-nuv");
        //  設定value
        this.setValue(this.options.value);
    },
    _destroy: function () {
        this.widget().html('');
    },
    setValue: function (value) {
        this._setValue(value);
        var rValP = +(this.rightValue / this.max * 100).toFixed(this.options.precision);
        var rValH = +(this.rightValue / this.max).toFixed(this.options.precision);
        this._setProgressBar(0, rValP);
        this._setRightHandler(rValH);
        this._setToolTip();
        // console.log(rValH);
    },
    _setValue: function (value) {
        value = this._constrainValue(value);
        this.rightValue = value - this.options.min;
        this.rightHandler.find('.tcg-handler-nuv>span').text(value.toFixed(this.options.precision));
        // console.log(Math.floor(value));
    },
    getValue: function () {
        return (this.rightValue + this.options.min).toFixed(this.options.precision);

    },
    _setRightHandler: function (position) {
        this.rightHandler.css("left", position * this.maxWidth / 100 + "rem");
    },
    _setToolTip: function (position) {
        this.toolTip.css("display", this.options.toolTip ? 'block' : 'none');
    },
    _setProgressBar: function (left, right) {
        var toolTipW = this.rightHandler.find(".tcg-handler-nuv>span").outerWidth();
        var handlerW = this.rightHandler.outerWidth();

        this.progressBar.css({
            left: left,
            width: (right - left) + '%',
            background: (right - left) == 100 && !this.options.disabled && this.options.maxColor ? this.options.maxColor : ''
        });
        this.rightHandler.css("borderColor", (right - left) == 100 && !this.options.disabled && this.options.maxColor ? this.options.maxColor : '');
        this.rightHandler.addClass((right - left) == 100 && !this.options.disabled && this.options.maxColor ? 'max-color' : '').removeClass((right - left) !== 100 && !this.options.disabled && this.options.maxColor ? 'max-color' : '');
        this.widget().find(".right-handler.max-color .tcg-handler-nuv>span").attr("style", (right - left) == 100 && !this.options.disabled && this.options.maxColor ? '--tooltip-bgcolor:'+this.options.maxColor : '');

        this.rightHandler.find(".tcg-handler-nuv>span").css({
            left: - toolTipW / 2 / 100 + handlerW / 2 / 100 + "rem"
        })
    },
    _rnd: function () {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

        for (var i = 0; i < 10; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    },
    _constrainRange: function (range) {
        return typeof range == 'boolean' ? range : false;
    },
    _constrainMin: function (min) {
        return $.isNumeric(min) ? Number(min) : 0;
    },
    _constrainMax: function (max) {
        return $.isNumeric(max) ? Number(max) : 100;
    },
    _constrainScale: function (scale) {
        return scale;
    },
    _constrainWidth: function (width) {
        return $.isNumeric(width) ? width : 120;
    },
    _constrainPrecision: function (precision) {
        return Number.isInteger(precision) ? Number(precision) : 0;
    },
    _constrainValue: function (value) {
        if (!$.isNumeric(value)) {
            return this.options.min;
        } else if (value < this.options.min) {
            return this.options.min;
        } else if (this.options.max != null && value > this.options.max) {
            return this.options.max;
        } else {
            var progVal = 0;
            if (!!this.options.scale) {
                if (!this.options.precision) {
                    progVal = Math.floor((value - this.options.min) / this.options.scale) * this.options.scale + this.options.min;
                } else {
                    var val = +value.toFixed(this.options.precision);
                    var roundVal = +((val - this.options.min) / this.options.scale).toFixed(this.options.precision);
                    var fixVal = +(roundVal * this.options.scale).toFixed(this.options.precision);
                    progVal = fixVal + this.options.min;
                }
            }
            return progVal;
        }
    },
    _constrainMaxColor: function (maxColor) {
        return typeof maxColor == 'string' ? maxColor : null;
    }
});
