/**
 *
 * @module _selectedWidget
 * @extends module:_remote
 */
$.widget("tcg._selectedWidget", $.tcg._remote, {
    version: "2.0.7",
    options: {
        /**
         * @memberOf module:_selectedWidget
         * @property {integer} [index=0] - 索引。0代表第一個按鈕，依續1,2,3，-1代表沒有選取
         * @instance
         */
        index: 0,
    },
    _create: function()  {
        this._super();
    },
    _init: function() {
        this._super();

        this.options.index = this._constrainIndex(this.options.index);
    },
    /**
     * <h4>取得索引值</h4>
     *
     * @instance
     *
     * @return {integer} 索引值
     */
    getIndex: function () {
        return this.options.index;
    },
    /**
     * <h4>設定索引值</h4>
     *
     * @instance
     *
     * @param index - 索引值
     */
    setIndex: function (index) {
        index = this._constrainIndex(index);

        if(index != this.options.index){
            this.options.index = index;
            /**
             * index改變時，觸發的事件
             *
             * @event change
             *
             * @param {Event} event - 事件
             * @param {object} data - 資料
             */
            this._trigger("change", null, {index: this.getIndex(), data: this.getSelectedData()});
        }
    },
    /**
     * <h4>取得選取的資料</h4>
     *
     * @instance
     *
     * @return {object}
     */
    getSelectedData: function () {
        return this.options.data[this.options.index];
    },
    _constrainIndex: function (index) {
        return Number.isInteger(index) ? index : 0;
    },
    _constrainData: function (data) {
        return Array.isArray(data) ? data: [];
    }
});
