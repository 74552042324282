(function ($) {
	var $element, $messager;
	var html = [],
		rnd = function() {
			var text = "";
			var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

			for (var i = 0; i < 10; i++)
				text += possible.charAt(Math.floor(Math.random() * possible.length));

			return text;
		},
		close = function () {
			$messager.removeAttr("id");
			$messager.off("click").find('.tcg-messager-wrap').empty();
			$element.hide();
		};

	//  generate messager html
	html.push('<div class="tcg-messager-overlay" style="display:none; positio:fixed; z-index:1000;">');
	// html.push('     <div class="tcg-widget tcg-messager tcg-corner-tl tcg-corner-tr"></div>');
	// 加入背景用的容器
	html.push('     <div class="tcg-widget tcg-messager tcg-corner-tl tcg-corner-tr"><div class="tcg-messager-wrap"></div></div>');
	html.push('</div>');

	//  append to body
	$element = $(html.join('')).appendTo("body");
	$messager = $element.find(".tcg-messager");

	/**
	 * 對話框
	 *
	 * @global
	 *
	 * @param {messagerOption} options - 控制對話框的參數集
	 */
	$.messager = function(options){
		if(options == "close"){
			close(); return;
		}else if(options == "isOpen"){
			return $messager.is(":visible");
		}

		/**
		 * @global
		 * @typedef messagerOption
		 *
		 * @property {string} [id=null] - ID。messager的id屬性，可以讓代碼透過$("#id").length來判斷是否有開啟messger
		 * @property {string} [type=success] - 型態。有三種：成功(success)、警告(warn)、錯誤(error)
		 * @property {string|array} [title=null] - 標題。接受字串或陣列，陣列的話則會使標題有多行顯示
		 * @property {string} [subtitle=null] - 副標題。接受字串或陣列，陣列的話則會使標題有多行顯示
		 * @property {string} [content=null] - html內容。html格式的內容，與content擇一呈現
		 * @property {string} [content=null] - 內容。與htmlContent擇一呈現
		 * @property {integer} [delay=null] - 延遲時間(單位：ms)。對話框顯示多久後關閉
		 * @property {boolean} [shadow=false] - 陰影。背景陰影
		 */
		options = $.extend({
			type: "success",     // success | warn | error
			id: null,
			title: null,
			subtitle: null,
			htmlContent: null,
			content: null,
			delay: null,
			shadow: true,
			fullWidthButtons: true,
			buttons: [{
				type: 'ok',
				text: "确定"
			}]
		}, options);
		var random = rnd();
		var html = [], button, style, clazz, timeoutId;

		//  icon
		html.push('     <div class="tcg-messager-icon tcg-icon tcg-icon-round-' + options.type + '"></div>');
		//  id
		if(options.id){
			$messager.attr("id", options.id);
		}else{
			$messager.removeAttr("id");
		}
		//  header
		if(options.title != null || options.subtitle != null){
			html.push('<div class="tcg-messager-header">');
			//  h3
			if($.isArray(options.title)){
				for(var i = 0, il = options.title.length; i < il; i++){
					html.push('<h3>' + options.title[i] + '</h3>');
				}
			}else if($.type(options.title) == "string"){
				html.push('<h3>' + options.title + '</h3>');
			}
			//  h4
			if($.isArray(options.subtitle)){
				for(var i = 0, il = options.subtitle.length; i < il; i++){
					html.push('<h4>' + options.subtitle[i] + '</h4>');
				}
			}else if($.type(options.subtitle) == "string"){
				html.push('<h4>' + options.subtitle + '</h4>');
			}
			html.push('</div>');    //  tcg-messager-header
		}
		//  body
		if(options.htmlContent != null || options.content != null){
			html.push('     <div class="tcg-messager-body">');
			if(options.htmlContent){
				html.push(options.htmlContent);
			}else if($.isArray(options.content)){
				for(var i = 0, il = options.content.length; i < il; i++){
					html.push('<p>' + options.content[i] + '</p>');
				}
			}else if(options.content){
				html.push('<p>' + options.content + '</p>');
			}
			html.push('     </div>');   //  tcg-messager-body
		}
		//  footer
		if(options.fullWidthButtons === true){
			html.push('     <div class="tcg-messager-footer tcg-messager-footer-full">');
		}
		else {
			html.push('     <div class="tcg-messager-footer tcg-messager-footer-default">');
		}
		for(var i = 0, il = options.buttons.length; i < il; i++){
			button = options.buttons[i], style = [];
			clazz = '';
			// style.push("width:" + (100/il) + "%");
			// footer 按鈕滿版設定
			if(options.fullWidthButtons === true){
				style.push("width:" + (100/il) + "%");
				clazz += i == 0 ? ' tcg-corner-br' : '';
				clazz += i == il - 1 ? ' tcg-corner-bl' : '';
			}
			// else {
			// 	clazz += 'tcg-widget tcg-button';
			// }
			if(button.color){
				style.push("background-color:" + button.color);
			}
			clazz += ' message-button-' + random + '-' + (i+1);
			clazz += button.type ? ' tcg-' + button.type : '';

			html.push('<button class="' + clazz + '" style="' + style.join(";") + '">' + button.text + '</button>');
		}
	html.push('     </div>');   //  tcg-messager-footer

		//  顯示訊息
		// $messager.html(html.join(''));
		// 加入背景用的容器
		$messager.find('.tcg-messager-wrap').html(html.join(''));
		$element.toggleClass("shadow", options.shadow).show();

		//  計算置中位置
		$messager.css({
			top: "calc(50% - " + ($messager.height() / 1.2 / 100) + "rem)",
			left: "calc(50% - " + ($messager.width() / 2 / 100) + "rem)"
		});

		//  set focus
		$messager.find(".message-button-" + random + "-1").focus();

		//  綁定事件
		options.buttons.forEach(function(button, index){
			//  任何按鈕按下，都必須解除綁定事件，並且移除視窗
			$messager.on("click", ".message-button-" + random + "-" + (index+1), function (event) {
				close();
				timeoutId ? clearTimeout(timeoutId): null;
				typeof button.onClick == "function" ? button.onClick() : null;
				typeof options.onClick == "function" ? options.onClick() : null;
			});
		});

		//  若有設定delay，則delay時間過後，移除視窗
		if(options.delay){
			timeoutId = setTimeout(function(){
				close();
			}, options.delay);
		}
	}
}(jQuery));