/**
 *
 * @module panel
 */
$.widget("tcg.panel", {
    version: "2.0.7",
    defaultElement: "<div></div>",
    options: {

    }
});
