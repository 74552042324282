/**
 *
 * @module datagrid
 * @extends module:_remote
 */
$.widget("tcg.datagrid", $.tcg._remote, {
    version: "2.0.7",
    defaultElement: '<div>',
    random: null,
    options: {
        /**
         * @memberOf module:datagrid
         * @property {integer} [page=1] - 當前頁數
         * @instance
         */
        page: 1,
        /**
         * @memberOf module:datagrid
         * @property {integer} [pageSize=16] - 一頁的資料量
         * @instance
         */
        pageSize: 16,
        /**
         * @memberOf module:datagrid
         * @property {string} [sortField=null] - 排序欄位
         * @instance
         */
        sortField: null,
        /**
         * @memberOf module:datagrid
         * @property {string} [sortType="asc"] - 排序方式。asc, desc
         * @instance
         */
        sortType: "asc",
        /**
         * @memberOf module:datagrid
         * @property {boolean} [pagination=true] - 是否有分頁
         * @instance
         */
        pagination: true,
        /**
         * @memberOf module:datagrid
         * @property {number | string} [height=200] - 報表高度
         * @instance
         */
        height: 200,
        /**
         * @memberOf module:datagrid
         * @property {object} [columns=[]] - 欄位資訊
         * @instance
         */
        columns: [],
        /**
         * @memberOf module:datagrid
         * @property {boolean} [pointSwitch=true] - 是否顯示小數點切換
         * @instance
         */
        pointSwitch: true,
        /**
         * @memberOf module:datagrid
         * @property {boolean} [isPoint=true] - 是否顯示小數點
         * @instance
         */
        isPoint: true,
        /**
         * @memberOf module:datagrid
         * @property {integer} [pointLength=1] - 小數點長度
         * @instance
         */
        pointLength: 1,
        /**
         * @memberOf module:datagrid
         * @property {string} [updateTimeFormat='yyyy-MM-dd hh:mm:ss'] - 更新時間格式
         * @instance
         */
        updateTimeFormat: 'yyyy-MM-dd hh:mm:ss',
        /**
         * @memberOf module:datagrid
         * @property {object} [loadText='数据加载中...'] - 更新時間格式
         * @instance
         */
        loadText: '数据加载中...',
        /**
         * @memberOf module:datagrid
         * @property {true} [autoLoad=true] - 是否自動加載
         * @instance
         */
        autoLoad: true,
        /**
         * @memberOf module:datagrid
         * @property {object} [scrollbar={}] - Datagrid Scrollbar設定 (Optional)
         * @property {string} [scrollbar.scrollClass='scrollbar-inner'] - Datagrid Scrollbar 套用樣式
         * @property {object} [scrollbar.offsetYObj={'left':'calc(100% - 60px)', 'z-index': '0'}] - Datagrid Scrollbar Y 樣式設定調整
         * @instance
         */
        scrollbar: {
            scrollClass: 'scrollbar-inner',
            offsetYObj: {
                'left': 'auto'
            }
        }
    },
    _create: function () {
        this._super();

        //  add tcg-datagrid class
        this.widget().addClass("tcg-widget tcg-datagrid");

        //  generate random id
        this.random = this._random(5);

        this._on(this.widget(), {
            "click .tcg-datagrid-update": function (e) {
                this.reload();
            },
            "click .tcg-pagination li.page-index": function (e) {
                var page = e.currentTarget.dataset.page;

                this.gotoPage(page);
            },
            "click .tcg-pagination .tcg-prev-button": function (e) {
                this.gotoPage(this.getPage() - 1 >= 1 ? this.getPage() - 1 : 1);
            },
            "click .tcg-pagination .tcg-next-button": function (e) {
                this.gotoPage(this.getPage() + 1 <= this.options.data.total ? this.getPage() + 1 : this.options.data.total);
            },
            "click .tcg-switch": function (e) {
                //  switch切換
                this.setIsPoint(!this.isPoint());
                //  資料重新加載
                this._showData(this.getData());
            }
        });
    },
    _init: function () {
        this.options.height = this._constrainHeight(this.options.height);
        this.options.columns = this._constrainColumns(this.options.columns);
        this.options.page = this._constrainPage(this.options.page);
        this.options.pageSize = this._constrainPageSize(this.options.pageSize);
        this.options.pagination = this._constrainPagination(this.options.pagination);
        this.options.pointSwitch = this._constrainPointSwitch(this.options.pointSwitch);
        this.options.isPoint = this._constrainIsPoint(this.options.isPoint);
        this.options.pointLength = this._constrainPointLength(this.options.pointLength);
        this.options.updateTimeFormat = this._constrainUpdateTimeFormat(this.options.updateTimeFormat);
        this.options.loadText = this._constrainLoadText(this.options.loadText);
        this.options.autoLoad = this._constrainAutoLoad(this.options.autoLoad);

        this.refresh();

        this._super();
    },
    refresh: function () {

        this._refresh();

        this._refreshHeader();
    },
    _refresh: function () {
        this.widget().css("width", parseFloat(this.options.width) / 100 + "rem");
        this.widget().css("height", parseFloat(this.options.height) / 100 + "rem");

        //  set template
        this.widget().html(this._template());

        // append datagrid scrollbar
        if (!!$(this).scrollbar && !!(typeof $(this).scrollbar === 'function')) {
            var scrollBody = $('.tcg-datagrid-body').find('.tcg-scrollable');
            var scrollClass = this.options.scrollbar.scrollClass || 'scrollbar-inner';
            var offsetYObj = this.options.scrollbar.offsetYObj || {};
            scrollBody.addClass(scrollClass).scrollbar();
            if (!!offsetYObj && !!Object.keys(offsetYObj).length) scrollBody.parent().find('.scroll-y').css(offsetYObj);
        }
    },
    _refreshHeader: function () {
        var html = [],
            temp, style = [];
        var _this = this;

        html.push('<tr>');
        style.push('<style>');
        html.push(' <th class="row-first"></th>');
        html.push(' <th class="row-second"></th>');
        this.options.columns.forEach(function (column, index) {

            //  generate html
            temp = '<th class="{th-class}">';
            temp += '   <div class="{div-sortable} {div-filtable} tcg-icon-hoverable">{title} {span-sortable} {span-filtable}</div>';
            temp += '</th>';

            //  replace variable
            temp = temp.replace('{th-class}', column.field + '-' + _this.random);
            temp = temp.replace('{hidden}', column.hidden ? 'display:none;' : '');
            temp = temp.replace('{div-sortable}', column.sortable ? 'tcg-datagrid-table-sortable' : '');
            temp = temp.replace('{div-filtable}', '');
            temp = temp.replace('{title}', column.title);
            temp = temp.replace('{span-sortable}', column.sortable ? '<span class="tcg-datagrid-table-condition"><span class="tcg-icon-small-arrow-down"></span></span>' : '');
            temp = temp.replace('{span-filtable}', '');
            html.push(temp);

            //  generate css
            style.push('.' + column.field + '-' + _this.random + '{');
            style.push('text-align:' + (column.align ? column.align : 'center') + ';');
            style.push('width:' + (column.width ? column.width / 100 + 'rem' : 'auto') + ';');
            style.push(column.hidden ? 'display:none;' : '');
            style.push('}');
        });
        html.push(' <th class="row-last-two"></th>');
        html.push(' <th class="row-last"></th>');
        html.push('</tr>');
        style.push('</style>');

        this.widget().find(".tcg-datagrid-header .tcg-datagrid-table").html(html.join(''));
        this.widget().append(style.join(''));
    },
    _refreshPagination: function (total) {
        var html = [],
            $pagination = this.widget().find(".tcg-pagination");

        //  1.是否分頁
        //  2.是否與前次總頁數相同？
        if (!this.options.pagination) {
            return;
        } else if (!total || Number($pagination.data("total")) == total) {
            return;
        }

        //  清空分頁
        $pagination.find("ul.tcg-pagination-list").empty();
        //  儲存總頁數
        $pagination.attr("data-total", total);

        //  總頁數大於1
        if (total <= 1) {
            $pagination.hide();
            return;
        }

        //  refresh pagination
        for (var i = 1; i <= total; i++) {
            html.push('<li class="page-index ' + (i != 1 && i != total ? 'tcg-hidden' : '') + '" data-page="' + i + '">' + i + '</li>');

            if (i == 1) {
                html.push('<li class="page-pre-dot tcg-hidden" >...</li>');
            }
            if (i + 1 == total) {
                html.push('<li class="page-next-dot tcg-hidden">...</li>');
            }
        }
        $pagination.find("ul.tcg-pagination-list").html(html.join(''));
        $pagination.show();
    },
    _destroy: function () {

    },
    /**
     * 生成template
     *
     * @returns {string}
     * @private
     */
    _template: function () {
        var html = '';

        //  header
        //      table
        //  body
        //      scrollable
        //          table
        //      empty
        //      loading
        //      footer
        //          table
        //  footer
        //      pagination
        //      info

        //  header
        html += '<div class="tcg-datagrid-header">';
        html += '   <table class="tcg-datagrid-table"><tbody></tbody></table>';
        html += '</div>';
        //  body
        html += '<div class="tcg-datagrid-body">';
        //  scrollable
        html += '   <div class="tcg-scrollable">';
        html += '       <table class="tcg-datagrid-table hoverable"><tbody></tbody></table>'
        html += '   </div>';
        //  empty
        html += '   <div class="tcg-datagrid-empty active"><div class="empty_wrap"></div><p class="empty_text"><span class="empty_message">无数据</span></p></div>';
        //  loading
        html += '   <div class="tcg-datagrid-loading"><div class="loading_wrap"><div><span class="iconfont_loading"></span></div><div><span class="iconfont_loading_inside"></span></div></div>';
        html += '       <p class="loading_text"><span class="loading_message">' + this.options.loadText + '</span></p>';
        //html += '       {loadSvg}';
        html += '   </div>';
        //  footer
        html += '   <div class="tcg-datagrid-footer" style="display: none;">';
        html += '       <table class="tcg-datagrid-table"><tbody></tbody></table>';
        html += '   </div>';
        html += '</div>';
        //  extension
        html += '<div class="tcg-datagrid-extension">';
        //  pagination
        html += '   <div class="tcg-inner-widget tcg-pagination" style="display: none;">';
        html += '       <span class="tcg-prev-button tcg-icon-activable"><span class="tcg-icon tcg-icon-arrow-left"></span></span>';
        html += '       <ul class="tcg-pagination-list"></ul>';
        html += '       <span class="tcg-next-button tcg-icon-activable"><span class="tcg-icon tcg-icon-arrow-right"></span></span>';
        html += '       <span class="tcg-clearfix"></span>';
        html += '   </div>';
        //  info
        html += '   <div class="tcg-datagrid-info">';
        html += '       <span class="tcg-update-time" style="margin-right:5px;"></span>';
        html += '       <span class="tcg-datagrid-update tcg-round-button"><span class="tcg-icon tcg-icon-cycle"></span></span>';
        html += '       <span class="tcg-datagrid-separate"></span>';
        html += '       <span style="margin-right:5px;">小数点</span>';
        html += '       <span class="tcg-inner-widget tcg-switch tcg-s24" status="on">';
        html += '           <span class="tcg-switch-handler"></span><span class="tcg-switch-text"></span>';
        html += '       </span>';
        html += '   </div>';
        html += '   <div class="tcg-clearfix"></div>';
        html += '</div>';

        return html;
    },
    /**
     * <h4>重新加載遠端資料</h4>null
     *
     * @instance
     *
     * @param {*} queryParams - 請求參數
     */
    reload: function (queryParams) {
        //  頁面重置為1
        this.options.page = 1;
        //  更新請求參數
        this.options.queryParams = queryParams != undefined ? queryParams : this.options.queryParams;

        //  加載遠端資料
        return this.load();
    },
    /**
     * <h4>加載遠端資料</h4>
     *
     * @instance
     *
     * @return {promise} ajax promise
     */
    load: function () {
        var data, _this = this;

        data = this.options.queryParams ? this.options.queryParams : {};
        //  sortField
        this.options.sortField = this._constrainSortField(this.options.sortField);
        //  sortType
        this.options.sortType = this._constrainSortType(this.options.sortType);
        //
        if (this.options.pagination && this.options.pageTransfer) {
            var result = this.options.pageTransfer(this.options.page, this.options.pageSize ? this.options.pageSize : this.default.pageSize);
            for (var key in result) {
                data[key] = result[key];
            }
        } else if (this.options.pagination) {
            data.page = this.options.page;
            data.pageSize = this.options.pageSize;
        }

        return this._load(data, function (jqXHR, settings) {

            //  clean table
            _this.widget().find(".tcg-datagrid-body table.tcg-datagrid-table tbody").empty();
            //  add processing status
            _this.widget().find(".tcg-datagrid-update").addClass("tcg-processing");
            //  show load animation
            _this.widget().find(".tcg-datagrid-loading").addClass("active")
                .siblings(".tcg-scrollable, .tcg-datagrid-empty").removeClass("active");
        }).always(function (rs) {

            //  remove processing status
            _this.widget().find(".tcg-datagrid-update").removeClass("tcg-processing");
            //  hide load animation
            _this.widget().find(".tcg-datagrid-loading").removeClass("active");
            //  update update_time
            _this.widget().find(".tcg-update-time").text('上次更新时间：' + _this._formatDate(new Date())); //new Date().format(_this.options.updateTimeFormat)
        }).fail(function (jqXHR) {

            //  移除加載動畫並顯示無資料
            _this.widget().find(".tcg-datagrid-empty").addClass("active");
        });
    },
    _showData: function () {
        //  資料格式{rows:[], footers:{}, total:{number},page:{number}}

        //  row data
        this._showRows(this.options.data.rows);
        //  footer data
        this._showFooter(this.options.data.footers);
        //  pagination
        this._refreshPagination(this.options.data.total);
        //  設定當前分頁
        this._setPage(this.options.data.page);
    },
    _showRows: function (rows) {
        var row, value, text, styles;
        var columns = this.options.columns,
            column;
        var html = [];

        //  無資料
        if (!rows || rows.length == 0) {
            this.widget().find(".tcg-datagrid-body .tcg-scrollable table.tcg-datagrid-table tbody").empty();
            this.widget().find(".tcg-datagrid-body .tcg-scrollable").removeClass("active");
            this.widget().find(".tcg-datagrid-body .tcg-datagrid-empty").addClass("active");
            return;
        }

        for (var i = 0; i < rows.length; i++) {
            row = rows[i];

            html.push('<tr>');
            html.push('    <td class="row-first"></td><td class="row-second"></td>');
            for (var j = 0; j < columns.length; j++) {
                column = columns[j];
                text = value = row[column.field];

                //  formatter
                if (column.formatter && typeof column.formatter == "function") {
                    text = column.formatter(text, row, i);
                }
                //  number formatter
                if (this.options.isPoint && typeof text == "number") {
                    text = this._formatNumber(text, this.options.pointLength, 3);
                } else if (typeof text == "number") {
                    text = this._formatNumber(parseInt(text));
                }
                //  styler
                if (column.styler && typeof column.styler == "function") {
                    styles = column.styler(value, row, i);
                } else {
                    styles = "";
                }

                html.push('<td class="' + column.field + '-' + this.random + '">');
                html.push('    <div class="column" style="' + styles + '">' + (text != null && text != undefined ? text : '&nbsp;') + '</div>');
                html.push('</td>');
            }
            html.push('    <td class="row-last-two"></td><td class="row-last"></td>');
            html.push('</tr>');
        }
        this.widget().find(".tcg-datagrid-body .tcg-scrollable table.tcg-datagrid-table tbody").html(html.join(''));
        this.widget().find(".tcg-datagrid-body .tcg-scrollable").addClass("active").scrollTop(0);
    },
    _showFooter: function (footers) {
        var footer, value;
        var columns = this.options.columns,
            column;
        var html = [];

        //      1.無資料
        if (!footers || footers.length == 0) {
            this.widget().find(".tcg-datagrid-body .tcg-scrollable table.tcg-datagrid-table").css("margin-bottom", 0);
            this.widget().find(".tcg-datagrid-footer").hide();
            this.widget().find(".tcg-datagrid-footer table.tcg-datagrid-table tbody").empty();
            return;
        }

        for (var i = 0; i < footers.length; i++) {
            footer = footers[i];

            html.push('<tr>');
            html.push('    <th class="row-first"></th><th class="row-second"></th>');
            for (var j = 0; j < columns.length; j++) {
                column = columns[j];
                value = footer[column.field];

                //  number formatter
                if (this.options.isPoint && $.isNumeric(value)) {
                    value = this._formatNumber(Number(value), this.options.pointLength, 3);
                } else if ($.isNumeric(value)) {
                    value = this._formatNumber(Number(value));
                }

                html.push('<th class="' + column.field + '-' + this.random + '">');
                html.push('    <div class="column">' + (value != null && value != undefined ? value : '&nbsp;') + '</div>');
                html.push('</th>');
            }
            html.push('    <th class="row-last-two"></th><th class="row-last"></th>');
            html.push('</tr>');
        }

        //  append html
        this.widget().find(".tcg-datagrid-footer table.tcg-datagrid-table tbody").html(html.join(''));
        this.widget().find(".tcg-datagrid-footer").show();
        //  若有footer，需加下margin-bottom
        this.widget().find(".tcg-datagrid-body .tcg-scrollable table.tcg-datagrid-table")
            .css("margin-bottom", this.widget().find(".tcg-datagrid-footer").outerHeight());
    },
    /**
     * <h4>前往第幾頁</h4>
     *
     * @param {integer} page - 頁數
     */
    gotoPage: function (page) {
        this.options.page = this._constrainPage(page);
        this.load();
    },
    /**
     * <h4>取得當前頁數</h4>
     *
     * @return {integer} 當前頁數
     */
    getPage: function () {
        return this.options.page;
    },
    _setPage: function (page) {
        if (!this.options.pagination) {
            return;
        }

        this.options.page = page;

        //  set selected
        this.widget().find(".tcg-pagination li[data-page='" + page + "']")
            .attr("selected", true).siblings("li").attr("selected", false);

        //  頁數小於5就全部顯示
        if (this.options.data.total <= 5) {
            this.widget().find(".tcg-pagination li.page-index").removeClass("tcg-hidden");
        } else {
            //  set dot is hidden
            this.widget().find(".tcg-pagination li.page-pre-dot").toggleClass("tcg-hidden", page == 1 || Math.abs(page - 2) < 3);
            this.widget().find(".tcg-pagination li.page-next-dot").toggleClass("tcg-hidden", page == this.options.data.total || page + 3 >= this.options.data.total);

            //  set page-index is hidden
            for (var i = 2; i <= this.options.data.total - 1; i++) {
                this.widget().find(".tcg-pagination li[data-page='" + i + "']").toggleClass("tcg-hidden", Math.abs(page - i) >= 3);
            }
        }
    },
    /**
     * <h4>設定是否顯示小數點</h4>
     *
     * @param {boolean} isPoint - 是否顯示小數點
     */
    setIsPoint: function (isPoint) {
        this.options.isPoint = this._constrainIsPoint(isPoint);

        this.widget().find(".tcg-datagrid-extension .tcg-switch").attr("status", this.options.isPoint ? 'on' : 'off');
    },
    /**
     * <h4>取得指定row的資料</h4>
     *
     * @param index - 索引值
     * @return {*}
     */
    getRowData: function (index) {
        return this.options.data.rows[index];
    },
    /**
     * <h4>是否顯示小數點</h4>
     *
     * @return {boolean}
     */
    isPoint: function () {
        return this.options.isPoint;
    },
    _random: function (length) {
        return new Array(length ? length : 5).join().replace(/(.|$)/g, function () {
            return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"]();
        });
    },
    _constrainWidth: function (width) {
        return width ? width : '100%';
    },
    _constrainPage: function (page) {
        return Math.floor(page) == page ? page * 1 : 1;
    },
    _constrainPageSize: function (pageSize) {
        return Math.floor(pageSize) == pageSize ? pageSize * 1 : 16;
    },
    _constrainSortField: function (sortField) {
        return typeof sortField == 'string' ? sortField : this.options.columns[0].field;
    },
    _constrainSortType: function (sortType) {
        return ['asc', 'desc'].indexOf(sortType) != -1 ? sortType : 'asc';
    },
    _constrainPagination: function (pagination) {
        return typeof pagination == 'boolean' ? pagination : true;
    },
    _constrainHeight: function (height) {
        return typeof height ? height : 200;
    },
    _constrainColumns: function (columns) {
        return $.isArray(columns) ? columns : [];
    },
    _constrainPointSwitch: function (pointSwitch) {
        return typeof pointSwitch == 'boolean' ? pointSwitch : true;
    },
    _constrainIsPoint: function (isPoint) {
        return typeof isPoint == 'boolean' ? isPoint : true;
    },
    _constrainPointLength: function (pointLength) {
        return Math.floor(pointLength) == pointLength ? pointLength * 1 : 1;
    },
    _constrainUpdateTimeFormat: function (updateTimeFormat) {
        return typeof updateTimeFormat == 'string' ? updateTimeFormat : 'yyyy-MM-dd hh:mm:ss';
    },
    _constrainLoadText: function (loadText) {
        return typeof loadText == 'string' ? loadText : '数据加载中...';
    },
    _constrainAutoLoad: function (autoLoad) {
        return typeof autoLoad == 'boolean' ? autoLoad : true;
    },
    /*
     * 格式化日期
     *
     * @param {Date} date - 日期
     * @return {string} 日期格式化後的字串
     *
     * @private
     */
    _formatDate: function (date) {
        var DEFAULT_TIME = ['上午', '下午'];
        var DEFAULT_WEEK = ['日', '一', '二', '三', '四', '五', '六'];

        //  Declare
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var time = parseInt(date.getHours() / 12);
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        var week = date.getDay();
        var millisecond = date.getMilliseconds();

        //  zero fill
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        minute = minute < 10 ? "0" + minute : minute;
        second = second < 10 ? "0" + second : second;

        var formatedDate = this.options.updateTimeFormat;
        // Mon Sep 17 2012 15:44:16 GMT+0800 (CST) to 2012-09-17 15:44:16
        formatedDate = formatedDate.replace("yyyy", year);
        formatedDate = formatedDate.replace("MM", month);
        formatedDate = formatedDate.replace("dd", day);
        formatedDate = formatedDate.replace("a", DEFAULT_TIME[time]);
        formatedDate = formatedDate.replace("HH", hour > 13 ? hour % 12 : hour);
        formatedDate = formatedDate.replace("hh", hour);
        formatedDate = formatedDate.replace("mm", minute);
        formatedDate = formatedDate.replace("ss", second);
        formatedDate = formatedDate.replace("SSS", millisecond);
        formatedDate = formatedDate.replace("E", DEFAULT_WEEK[week]);
        return formatedDate;
    },
    /*
     * 格式化數字
     * @param {number} number - 數字
     * @param {number} [n=0] - 小數點位數
     * @param {number} [x=3] - 分位。例如千分位(3)、萬分位(4)
     * @return {string} 數字格式化後的字串
     *
     * @private
     */
    _formatNumber: function (number, n, x) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    }
});
