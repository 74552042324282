/**
 *
 * @module _multipleSelectedWidget
 * @extends module:_singleSelectedWidget
 */
$.widget("tcg._multipleSelectedWidget", $.tcg._singleSelectedWidget, {
    version: "2.0.7",
    options: {
        /**
         * @memberOf module:_multipleSelectedWidget
         * @property {boolean} [multiple=false] - 是否複選
         * @instance
         */
        multiple: false
    },
    _create: function()  {
        this._super();
    },
    _init: function() {
        this.options.multiple = this._constrainMultiple(this.options.multiple);
        this._super();
    },
    refresh: function () {

    },
    _destroy: function() {

    },
    /**
     * <h4>取得索引值</h4>
     *
     * @instance
     *
     * @return {integer|array} 索引值
     */
    getIndex: function () {
        return this.options.index;
    },
    getSelectedData: function () {
        var data = [];

        if(!Array.isArray(this.options.index)){
            return this._superApply();
        }else{
            for(var i = 0, j = this.options.index.length; i < j; i++){
                data.push(this.options.data[this.options.index[i]]);
            }
            return data;
        }
    },
    _constrainMultiple: function (multiple) {
        return typeof multiple == 'boolean' ? multiple : false;
    },
    _constrainIndex: function (index) {
        //  判斷是單選還是複選
        if(this.options.multiple){
            if(Array.isArray(index) && index.length > 0){
                return index;
            }else{
                return [];
            }
        }else{
            return Number.isInteger(index) ? Number(index) : 0;
        }
    }
});