/**
 * 
 * @module numberSpinner
 * @extends module:_widget
 */
$.widget("tcg.numberSpinner", $.tcg._widget, {
    version: "2.0.7",
    defaultElement: "<div>",
    options: {
        classes: {
            "tcg-number-spinner": "tcg-corner-all",
            "tcg-number-spinner-left-button": "tcg-corner-tl tcg-corner-bl tcg-unselected",
            "tcg-number-spinner-right-button": "tcg-corner-tr tcg-corner-br tcg-unselected"
        },
        /**
         * @memberOf module:numberSpinner
         * @property {number} [min=0] - 最小值
         * @instance
         */
        min: 0,
        /**
         * @memberOf module:numberSpinner
         * @property {number} [min=null] - 最大值，null的時候表示不限
         * @instance
         */
        max: null,
        /**
         * @memberOf module:numberSpinner
         * @property {number} [scale=1] - 尺度，每次點擊按鈕數值變化的程度
         * @instance
         */
        scale: 1,
        /**
         * @memberOf module:numberSpinner
         * @property {number} [width=120] - 元件寬度
         * @instance
         */
        width: 120,
        /**
         * @memberOf module:numberSpinner
         * @property {integer} [precision=0] - 精確度
         * @instance
         */
        precision: 0,
        /**
         * @memberOf module:numberSpinner
         * @property {string} [suffix=''] - 後綴文字
         * @instance
         */
        suffix: '',
        /**
         * @memberOf module:numberSpinner
         * @property {boolean} [editable=true] - 是否可編輯
         * @instance
         */
        editable: true,
        /**
         * @memberOf module:numberSpinner
         * @property {number} [value=1] - 數值
         * @instance
         */
        value: 1
    },
    _create: function () {
        this._super();

        //  生成子元素
        this.element.append('<span class="tcg-number-spinner-left-button"><span class="tcg-icon tcg-icon-left-arrow"></span></span>');
        //this.input = $('<span class="tcg-input"></span>').appendTo(this.element);
		this.input = $('<input class="tcg-input"/>').appendTo(this.element);
        this.element.append('<span class="tcg-number-spinner-right-button"><span class="tcg-icon tcg-icon-right-arrow"></span></span>');

        //  加載class
        this._addClass("tcg-number-spinner tcg-corner-all");
        var _this = this;
        $.each(this.options.classes, function(key, value){
            _this.element.find("." + key).addClass(value);
        });

        //  事件監聽
        this._on(this.element, {
            "focusin input.tcg-input": function(e){
				var value = this.getValue();

				this.input[0].value = value;

				//  select all text
				if(!this.input.attr("readonly")){
					this.input.select();
				}

                /**
                 * 鎖定輸入框時，觸發的事件
                 *
                 * @event focusin
                 *
                 * @param {Event} event - 事件
                 * @param {Object} data - 資料{value}
                 */
                this._trigger("focusin", e, {value: this.getValue()});
            },
            "focusout input.tcg-input": function(e){

				//  reset value
				this.setValue(this.input[0].value);

                /**
                 * 離開輸入框時，觸發的事件
                 *
                 * @event focusout
                 *
                 * @param {Event} event - 事件
				 * @param {Object} data - 資料{value}
                 */
				this._trigger("focusout", e, {value:this.getValue()});
            },
            "click .tcg-number-spinner-left-button": function(e){
                var $target = $(e.currentTarget);

                $target.css("pointer-events", "none");
                this.setValue(this.getValue() - this.options.scale);
				$target.css("pointer-events", "");
                /**
                 * 點擊計數按鈕時，觸發的事件
                 *
                 * @event click
                 *
                 * @param {Event} event - 事件
				 * @param {Object} data - 資料{value}
                 */
                this._trigger("click", e, {value: this.getValue()});
            },
            "click .tcg-number-spinner-right-button": function(e){
				var $target = $(e.currentTarget);

				$target.css("pointer-events", "none");
                this.setValue(this.getValue() + this.options.scale);
				$target.css("pointer-events", "");

                //  trigger
                this._trigger("click", e, {value: this.getValue()});
            }
        });
    },
    _init: function () {
        this._super();

        this.options.editable = this._constrainEditable(this.options.editable);
        this.options.width = this._constrainWidth(this.options.width);
        this.options.min = this._constrainMin(this.options.min);
        this.options.max = this._constrainMax(this.options.max);
        this.options.scale = this._constrainScale(this.options.scale);
        this.options.value = this._constrainValue(this.options.value);
        this.options.suffix = this._constrainSuffix(this.options.suffix);
        this.options.precision = this._constrainPrecision(this.options.precision);

        this.refresh();
    },
    refresh: function () {
        //  設定高度
        this.options.size != null ? this.element.addClass("tcg-s" + this.options.size) : this.element.removeClass(this.allSizeClasses.join(" "));
        //  設定寬度
        this.input.css("width", (this.options.width - this.element.find(".tcg-number-spinner-left-button").width() - this.element.find(".tcg-number-spinner-right-button").width()) / 100 + "rem");
		//  是否可編輯
		this.input.attr("readonly", !this.options.editable);
		//  是否禁用
		this.input.attr("disabled", this.options.disabled);
        //  限制只能輸入數字、小數點
        this.input.attr("onkeypress", "return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46");
        //  設定數值
        this._setText(this.options.value);
        //  是否禁用
        this.element.attr("disabled", this.options.disabled);
        this.element.find(".tcg-icon-left-arrow").toggleClass("tcg-icon-disabled-left-arrow", this.options.disabled);
        this.element.find(".tcg-icon-right-arrow").toggleClass("tcg-icon-disabled-right-arrow", this.options.disabled);
    },
    _destroy: function () {
        this._removeClass("tcg-number-spinner tcg-corner-all");
        this.element.children().remove();
    },
    _setText: function (text) {
        text = Number(text).toFixed(this.options.precision) + this.options.suffix;

        this.input[0].value = text;
    },
    /**
     * <h4>判斷是否可手動編輯</h4>
     *
     * @instance
     *
     * @return {boolean} true可編輯、false不可編輯
     */
    isEditable: function () {
        return this.options.editable;
    },
    /**
     * <h4>取得元件寬度</h4>
     *
     * @instance
     *
     * @return {number} 元件寬度
     */
    getWidth: function () {
        return this.options.width;
    },
    /**
     * <h4>取得最小值</h4>
     *
     * @instance
     *
     * @return {number} 最小值
     */
    getMin: function () {
        return this.options.min;
    },
    /**
     * <h4>取得最大值</h4>
     *
     * @instance
     *
     * @return {number} 最大值
     */
    getMax: function () {
        return this.options.max;
    },
    /**
     * <h4>取得尺度</h4>
     *
     * @instance
     *
     * @return {number} 尺度
     */
    getScale: function () {
        return this.options.scale;
    },
    /**
     * <h4>取得後綴文字</h4>
     *
     * @instance
     *
     * @return {string} 後綴文字
     */
    getSuffix: function () {
        return this.options.suffix;
    },
    /**
     * <h4>取得小數點位數</h4>
     *
     * @instance
     *
     * @return {integer} 小數點位數
     */
    getPrecision: function () {
        return this.options.precision;
    },
    /**
     * <h4>設定數值</h4>
     *
     * @instance
     *
     * @param {number} value - 數值
     */
    setValue: function (value) {
        value = this._constrainValue(value);

        if(value != this.options.value){
            this.options.value = value;

            /**
             * 數值改變時，觸發的事件
             *
             * @event change
             *
             * @param {Event} event - 事件
             * @param {Number} value - 數值
             */
            this._trigger("change", this.options.value);
        }
        this._setText(this.options.value);
    },
    /**
     * <h4>取得數值</h4>
     *
     * @instance
     *
     * @return {number} 數值
     */
    getValue: function () {
        return this.options.value;
    },
    _constrainEditable: function (editable) {
        return typeof editable == "boolean" ? editable : true;
    },
    _constrainWidth: function (width) {
        return $.isNumeric(width) ? width : 100;
    },
    _constrainMin: function (min) {
        return $.isNumeric(min) && min < this.options.max ? Number(min) : 0;
    },
    _constrainMax: function (max) {
        return $.isNumeric(max) && max > this.options.min ? Number(max) : null;
    },
    _constrainScale: function (scale) {
        return $.isNumeric(scale) ? Number(scale) : 1;
    },
    _constrainValue: function (value) {
        if(!$.isNumeric(value)){
            return this.options.min;
        }else if(value < this.options.min){
            return this.options.min;
        }else if(this.options.max != null && value > this.options.max){
            return this.options.max;
        }else{
            return Number(value);
        }
    },
    _constrainSuffix: function (suffix) {
        return typeof suffix == "string" ? suffix : "";
    },
    _constrainPrecision: function (precision) {
        return Number.isInteger(precision) ? Number(precision) : 0;
    }
});

$.fn.selectText = function() {
    var doc = document;
    var element = this[0];
    if (doc.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}
